<template>
  <div>
    <page-header :title="title" :url="url" :titleNew="titleNew"></page-header>
    <grid
      ref="grid"
      :url="urlDataSource"
      :source="source"
      :columns="columns"
      :grid="grid"
    ></grid>
  </div>
</template>

<script>
import swal from '@/utils/swal'
import Services from '@/utils/services'
import PageHeader from '@/components/PageHeader'
import Grid from '@/components/Grid'

import GridTemplate from '@/mixins/GridTemplate'
import Permission from '@/mixins/Permission'

export default {
  mixins: [GridTemplate, Permission],
  components: {
    PageHeader,
    Grid
  },
  computed: {
    source() {
      return [
        {
          serverFiltering: true,
          pageSize: 100,
          serverPaging: true,
          schemaData: 'data',
          schemaTotal: 'total',
          serverSorting: true,
          schemaParse: this.parse
        }
      ]
    }
  },
  data() {
    return {
      title: 'Videos',
      titleNew: '',
      url: '/sistema/videos/',
      urlDataSource: `${this.$url}/videos/all`,
      columns: [
        {
          field: 'title',
          title: 'Título',
          width: 200
        },
        {
          field: 'created_at',
          title: 'Dt Cadastro',
          format: '{0:dd/MM/yyyy HH:mm}',
          width: 120
        }
      ],
      grid: [
        {
          height: 500,
          navigatable: true,
          columnMenu: true,
          sortable: false,
          pageable: true,
          pageablePageSizes: [15, 50, 75, 100],
          pageableAlwaysVisible: true
        }
      ]
    }
  },
  methods: {
    parse(d) {
      d.data.map(i => {
        i.created_at = new Date(i.created_at)
        return i
      })
      return d
    },
    async getApagar(id) {
      const dialog = await swal.dialog(
        'Excluir',
        'Deseja excluir esse registro?'
      )
      if (dialog.value) {
        await Services.delete(`/videos/${id}`)
        swal.success('Excluido!', 3000)
        this.$refs.grid.$refs.datasource1.kendoDataSource.read()
      }
    },
    verifPermissao() {
      this.titleNew = this.ACTION.c ? 'Novo' : ''
      if (this.ACTION.e) {
        this.columns.push({
          field: 'ativo',
          title: 'Ações',
          width: 105,
          template: this.commandTemplate
        })
      }
    }
  },
  created() {
    this.verifPermissao()
  }
}
</script>
